<div class="page-title mb-4">
  <p-toast></p-toast>
  <h1>Upload Documents</h1>
</div>
<div class="d-flex flex-column flex-lg-row align-items-stretch">
  <div class="flex-grow-1 pe-lg-5">
    <form
      class="main-form py-4"
      [formGroup]="uploadDocumentsForm"
      (ngSubmit)="addEtwinSupportDocuments()"
    >
      <div class="container px-0">
        <div class="row">
          <div class="col-xxl-6">
            <div class="select-box mb-4">
              <p-dropdown
                [options]="ribbonPageList"
                (onChange)="onRibbonPageChange()"
                [(ngModel)]="selectedRibbonPage"
                optionLabel="ribbonsPageName"
                [showClear]="true"
                placeholder="Select a Department"
                formControlName="ribbonPagename"
                [ngClass]="{
                  'is-invalid': submitted && f['ribbonPagename'].errors
                }"
              ></p-dropdown>
              <div
                *ngIf="submitted && f['ribbonPagename'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['ribbonPagename'].errors['required']">
                  Please selet Department
                </div>
              </div>
              <label class="select-label" for="input-level2">Department*</label>
            </div>

            <div class="select-box mb-4">
              <p-dropdown
                [options]="ribbonPageGroupList"
                (onChange)="onRibbonPageGroupChange()"
                [(ngModel)]="selectedRibbonPageGroup"
                optionLabel="ribbonsPageGroupName"
                [showClear]="true"
                placeholder="Select a Page"
                formControlName="ribbonPageGroupname"
                [ngClass]="{
                  'is-invalid': submitted && f['ribbonPageGroupname'].errors
                }"
              ></p-dropdown>
              <div
                *ngIf="submitted && f['ribbonPageGroupname'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['ribbonPageGroupname'].errors['required']">
                  Please selet Page
                </div>
              </div>
              <label class="select-label" for="three-level2">Page*</label>
            </div>

            <div class="select-box mb-4">
              <p-dropdown
                [options]="ribbonPageGroupButtonList"
                [(ngModel)]="selectedRibbonPageGroupBtton"
                optionLabel="ribbonsPageGroupButtonName"
                [showClear]="true"
                placeholder="Select a Button"
                formControlName="ribbonPageGroupButtonname"
                [ngClass]="{
                  'is-invalid':
                    submitted && f['ribbonPageGroupButtonname'].errors
                }"
              ></p-dropdown>
              <div
                *ngIf="submitted && f['ribbonPageGroupButtonname'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['ribbonPageGroupButtonname'].errors['required']">
                  Please select Button
                </div>
              </div>
              <label class="select-label" for="three-level2">Button*</label>
            </div>

            <div class="mb-4">
              <p>Type*</p>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="rtype"
                  [(ngModel)]="documentType"
                  (change)="onrChange($event)"
                  id="guide"
                  value="Guide"
                  checked
                />
                <label class="form-check-label" for="guide">Guide</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="rtype"
                  [(ngModel)]="documentType"
                  (change)="onrChange($event)"
                  id="knowledge"
                  value="Knowledge"
                />
                <label class="form-check-label" for="knowledge"
                  >Knowledge</label
                >
              </div>
            </div>
          </div>
          <div class="col-xxl-6">
            <div class="position-relative">
              <!-- <input type="file" class="upload_hide" id="upload_custom" multiple="">  -->

              <input
                type="file"
                #file
                placeholder="Choose file"
                (change)="uploadFile(file.files)"
                formControlName="uploadDocumentName"
                [ngClass]="{
                  'is-invalid': submitted && f['uploadDocumentName'].errors
                }"
              />
              <div
                *ngIf="submitted && f['uploadDocumentName'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['uploadDocumentName'].errors['required']">
                  Please upload Document
                </div>
              </div>
              <div>
                <span style="color: red"
                  >Note*: Only upload pdf, doc(x) and html documents</span
                >
              </div>

              <!-- <button type="button" class="btn btn-success" (click)="file.click()">Upload File</button> -->
              <!-- <label for="upload_custom" class="upload_label position-relative">
                    <div class="image">
                      <img src="" alt="">
                    </div>
                    <p class="document-name d-none mx-3"></p>
                    <figure class="mb-0 flex-shrink-0 icon-upload">
                      <img class="img-fluid" src="./assets/images/icons/icon-upload.png" alt="">
                    </figure>

                    <p class="drag_text">
                      Drag Documents here (Or)<br>
                      Click Here to Upload
                    </p>
                    <button class="choose_file">Choose a File</button> 
                  </label> -->
              <button type="button" class="delete_file mt-3 d-none">
                Delete File
              </button>
            </div>
            <button type="submit" class="btn btn-primary btn-upload">
              Upload
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-border border border-dark p-2 mt-4">
  <div class="d-flex justify-content-center">
    <div class="table-responsive p-0 w-100" style="max-height: 300px">
      <app-upload-documents-grid
        [inputSupportDocuments]="parentsupportDocuments"
      ></app-upload-documents-grid>
    </div>
  </div>
</div>
