<form
  class="main-form py-4"
  [formGroup]="ribbonPageForm"
  (ngSubmit)="addRibbonPage()"
>
  <div class="form-floating mb-3 form-group">
    <input
      type="text"
      class="form-control"
      id="ribbonPagename"
      name="ribbonPagename"
      formControlName="ribbonPagename"
      placeholder="Enter Department"
      [ngClass]="{ 'is-invalid': submitted && f['ribbonPagename'].errors }"
      [(ngModel)]="addRibbionPageReq.ribbonsPageName"
    />
    <div
      *ngIf="submitted && f['ribbonPagename'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="f['ribbonPagename'].errors['required']">
        Department is required
      </div>
    </div>
    <label for="input-level1">Department*</label>
  </div>
  <button type="submit" class="btn btn-primary btn-save">Save</button>
</form>
<div class="table-responsive p-0 w-100" style="max-height: 300px">
  <table class="table table-striped m-0">
    <thead class="table-primary position-sticky top-0">
      <tr>
        <th>Department</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf = "ribbonPagesGridData && ribbonPagesGridData.length;else other_content">
        <ng-container *ngFor="let ribbonPage of ribbonPagesGridData">
          <tr>
            <td>{{ ribbonPage.ribbonsPageName }}</td>
  
            <td>
              <button
                (click)="editRibbonPage(ribbonPage.id)"
                class="btn btn-primary btn-sm"
              >
                <i class="fa fa-edit xs mx-1"></i>Edit
              </button>
            </td>
            <ng-container *ngIf="!ribbonPage.isDelete;else other_content_td">
              <td >
                <button
                  (click)="deleteRibbonPage(ribbonPage.id)"
                  class="btn btn-primary btn-sm"
                >
                  <i class="fa fa-trash xs mx-1"></i>Delete
                </button>
              </td>

            </ng-container>
            <ng-template #other_content_td>
              <td >
                
              </td>
            </ng-template>
           
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #other_content>  <tr  id="loading-overlay">
        <td colspan="7" class="loading-icon text-center"> 
            <img src="../../../assets/images/loading.gif" style="width:80px;height:80px;" />
        </td>
        </tr>
      </ng-template>

    </tbody>
  </table>
</div>
<!-- <p-table
  [value]="ribbonPages"
  styleClass="p-datatable-gridlines"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Department</th>
      <th>Edit</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-ribbonPage>
    <tr>
      <td>{{ ribbonPage.ribbonsPageName }}</td>
      <td>
        <p-button
          label="Edit"
          [raised]="true"
          severity="danger"
          (onClick)="editRibbonPage(ribbonPage.id)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</p-table> -->
