import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AddLevelsService } from '../../../services/add-levels.service';
import { RibbonPageGroup } from '../../../models/ribbon-page-group-model';
import { RibbonPage } from '../../../models/ribbon-page-model';
import { Subscription } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-ribbons-page-group',
  templateUrl: './ribbons-page-group.component.html',
  styleUrl: './ribbons-page-group.component.css',
})
export class RibbonsPageGroupComponent implements OnInit {
  @Output() onShowToastMessage = new EventEmitter<any>();
  private subscription = new Subscription();
  ribbonPageList: RibbonPage[] | undefined;
  selectedRibbonPage: RibbonPage | undefined;
  ribbonPageGroupsGridData: RibbonPageGroup[] = [];
  ribbonPageGroupsData: RibbonPageGroup[] = [];
  ribbonPageGroupForm: FormGroup = new FormGroup({
    ribbonPagename: new FormControl(''),
    ribbonPageGroupname: new FormControl(''),
  });
  submitted = false;
  createdBy = '';
  addRibbionPageGroupReq: RibbonPageGroup = {
    id: 0,
    idRibbonsPage: 0,
    ribbonsPageName: '',
    ribbonsPageGroupName: '',
    createdBy: this.createdBy,
    createdDate: new Date(),
    updatedBy: this.createdBy,
    updatedDate: new Date(),
    isDelete: false,
  };
  constructor(
    private addLevelsService: AddLevelsService,
    private formBuilder: FormBuilder
  ) {}
  loadRibbonPages() {
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        this.ribbonPageList = ribbonPages;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  loadRibbonPageGroups() {
    this.addLevelsService.getAllRibbonPageGroups(0).subscribe({
      next: (ribbonPageGroups) => {
        //this.ribbonPageGroups=ribbonPageGroups;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  loadGridRibbinPageGroups() {
    this.addLevelsService.getAllGridRibbonPageGroups().subscribe({
      next: (ribbonPageGroups) => {
        this.ribbonPageGroupsGridData = ribbonPageGroups;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }

  ngOnInit(): void {
    this.loadRibbonPages();
    this.ribbonPageGroupForm = this.formBuilder.group({
      ribbonPagename: ['', Validators.required],
      ribbonPageGroupname: ['', Validators.required],
    });
    this.loadGridRibbinPageGroups();
    this.subscription = this.addLevelsService.currentRibbionPageData.subscribe(
      (data) => {
        this.ribbonPageList = data;
      }
    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.ribbonPageGroupForm.controls;
  }
  addRibbonPageGroup() {
    this.submitted = true;

    if (this.ribbonPageGroupForm.invalid) {
      return;
    }
    this.addRibbionPageGroupReq.idRibbonsPage = this.selectedRibbonPage?.id;
    this.addLevelsService
      .addRibbonPageGroup(this.addRibbionPageGroupReq)
      .subscribe({
        next: (addRibbionPageGroupReq) => {
          this.addLevelsService.getAllGridRibbonPageGroups().subscribe({
            next: (ribbonPageGroups) => {
              this.addLevelsService.setRibbionPageGroupData(ribbonPageGroups);
              this.ribbonPageGroupsGridData = ribbonPageGroups;
              this.clearRibbonPageGroupFields();
              this.onShowToastMessage.emit({
                msg: 'Page Saved Successfully',
                type: 'success',
              });
            },
            error: (response) => {
              console.log(response);
              if (response.status === 401) {
                this.onShowToastMessage.emit({
                  msg: 'Unauthorized',
                  type: 'error',
                });
              } else {
                this.onShowToastMessage.emit({
                  msg: 'Error while Saving Page',
                  type: 'error',
                });
              }
            },
          });
        },
        error: (response) => {
          console.log(response);
        },
      });
  }
  clearRibbonPageGroupFields() {
    this.addRibbionPageGroupReq.id = 0;
    this.addRibbionPageGroupReq.idRibbonsPage = 0;
    this.addRibbionPageGroupReq.ribbonsPageName = '';
    (this.addRibbionPageGroupReq.ribbonsPageGroupName = ''),
      (this.addRibbionPageGroupReq.createdBy = ''),
      (this.addRibbionPageGroupReq.createdDate = new Date()),
      (this.addRibbionPageGroupReq.updatedBy = ''),
      (this.addRibbionPageGroupReq.updatedDate = new Date());
    this.selectedRibbonPage = undefined;
  }
  getRibbonPageGroupById(id: number) {
    this.ribbonPageGroupsData = JSON.parse(
      JSON.stringify(this.ribbonPageGroupsGridData)
    );
    var obj = this.ribbonPageGroupsData.filter(function (node) {
      return node.id == id;
    });

    return obj[0];
  }
  getRibbonPageById(id: number) {
    return this.ribbonPageList?.filter(function (node) {
      return node.id == id;
    })[0];
  }
  async editRibbionPageGroup(Id: number) {
    console.log(Id);
    console.log(this.getRibbonPageGroupById(Id));
    this.addRibbionPageGroupReq = this.getRibbonPageGroupById(Id);
    this.selectedRibbonPage = this.getRibbonPageById(
      this.addRibbionPageGroupReq.idRibbonsPage
    );
  }
  async deleteRibbonPageGroup(ribbonPageGroupId: number) {
    this.addLevelsService.deleteRibbonPageGroup(ribbonPageGroupId).subscribe({
      next: (ribbonPageGroups) => {
        this.addLevelsService.setRibbionPageGroupData(ribbonPageGroups);
        this.ribbonPageGroupsGridData = ribbonPageGroups;
        this.onShowToastMessage.emit({
          msg: 'Page deleted Successfully',
          type: 'success',
        });
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        } else {
          this.onShowToastMessage.emit({
            msg: 'Error while deleting Page',
            type: 'error',
          });
        }
      },
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
