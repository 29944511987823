<!--Backbutton code-->
<p-toast></p-toast>
<span
  id="back"
  *ngIf="!isRibbionPageMenu"
  class="btn-back"
  (click)="backButtonClick()"
  >Back</span
>

<div class="mb-2 mx-3">
  <input
    type="text"
    [(ngModel)]="searchText"
    class="form-control input-search rounded-pill"
    placeholder="Search Levels"
  />
</div>

<ul
  class="nav flex-column position-relative main-nav"
  *ngIf="isRibbionPageMenu"
>
  <li
    (click)="isMenuOpen(false)"
    class="nav-item"
    *ngFor="let department of ribbonPages | filter : searchText"
  >
    <span (click)="ribbonPageMenuClick(department?.id)" class="nav-link">{{
      department?.ribbonsPageName
    }}</span>
  </li>
</ul>
<ul class="nav flex-column position-relative main-nav">
  <li class="nav-item">
    <ul
      class="nav flex-column sub-menu one open position-relative p-1"
      *ngIf="isRibbionPageGroupMenu"
    >
      <li
        (click)="isMenuOpen(false)"
        class="nav-item"
        *ngFor="let page of ribbonPageGroups | filter : searchText"
      >
        <span (click)="ribbonPageGroupMenuClick(page?.id)" class="nav-link">{{
          page.ribbonsPageGroupName
        }}</span>
      </li>
    </ul>
  </li>
</ul>

<ul class="nav flex-column position-relative main-nav">
  <li class="nav-item">
    <ul class="nav flex-column sub-menu one open position-relative p-1">
      <li class="nav-item">
        <ul
          class="nav flex-column sub-menu two open position-relative p-0"
          *ngIf="isRibbionPageGroupButtonMenu"
        >
          <li
            (click)="isMenuOpen(false)"
            class="nav-item"
            *ngFor="let button of ribbonPageGroupButtons | filter : searchText"
          >
            <a
              class="nav-link"
              (click)="ribbonPageGroupButtonMenuClick(button?.id)"
              >{{ button.ribbonsPageGroupButtonName }}</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
