import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrl: './document-view.component.css',
})
export class DocumentViewComponent implements OnInit {
  constructor(private platformlocation: PlatformLocation) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
  }

  ngOnInit() {}
}
