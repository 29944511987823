import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UploadDocumentsService } from '../../../services/upload-documents.service.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HtmlFetchService } from '../../../services/html-fetch.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrl: './document-viewer.component.css',
  providers: [MessageService],
})
export class DocumentViewerComponent implements OnInit {
  documentName = '';
  docExtention = '';
  doc = '';
  urlSafe: SafeResourceUrl = '';
  htmlContent: any;

  baseApiUrl: string = environment.baseApiUrl;
  wordDocumentUrl: string = environment.wordDocumentUrl;
  constructor(
    private uploadDocumentService: UploadDocumentsService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private htmlFetchService: HtmlFetchService,
    private sanitizer: DomSanitizer,
    private platformlocation: PlatformLocation
  ) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
  }
  showToastMessage(msg: string, type: string) {
    if (type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
      });
    if (type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: msg,
      });
  }
  getHTMLContent(filePath: string) {
    this.htmlFetchService.getHtmlContent(filePath).subscribe(
      (content: string) => {
        this.htmlContent = content;
      },
      (error) => {
        this.showToastMessage('Error fetching HTML content:' + error, 'error');
      }
    );
  }

  loadViewDocumentByButtonId(Id: any) {
    this.uploadDocumentService
      .getEtwinSupportDocumentsByButtonId(Id)
      .subscribe({
        next: (documents) => {
          if (documents.length) {
            var url = documents[0].documentPath.replaceAll('\\', '/');
            //url = url.replaceAll('\\', '/');
            this.doc =
              this.baseApiUrl + 'Resources' + url.split('Resources')[1];
            this.docExtention = documents[0].documentExt;
            console.log(this.docExtention,documents[0]);
            if (documents && documents.length && documents[0].documentName.length>18){
              this.documentName =`${documents[0].documentName.substring(0,18)}...${this.docExtention}`

            } else {
              this.documentName =`${documents[0].documentName}${this.docExtention}`

            }
           
            var pos = documents[0].documentName.lastIndexOf('_');
            // this.documentName =
            //   documents[0].documentName.substring(0, pos) +
            //   documents[0].documentExt;
            if (this.docExtention === '.pdf') {
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.doc
              );
            } else if (
              this.docExtention === '.doc' ||
              this.docExtention === '.docx'
            ) {
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.wordDocumentUrl + this.doc
                //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                //'https://view.officeapps.live.com/op/embed.aspx?src=https://localhost:7242/Resources/Documents/File_Word_20240207200952737.docx'
                //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
                //'https://view.officeapps.live.com/op/embed.aspx?src=https://stackblitz.com/storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdkpMIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e75389b18343665404852ed4cba8bd25938fa9bd/file-sample_1MB.doc'
                //'https://docs.google.com/gview?url=' + this.doc
                //https://view.officeapps.live.com/op/embeded.aspx?src=https://localhost:7242/Resources/Documents/File_Word_20240207200952737.docx
              );
            } else if (
              this.docExtention === '.html' ||
              this.docExtention === '.htm'
            ) {
              this.htmlContent = this.getHTMLContent(this.doc);
            } else {
              this.doc = '';
              this.htmlContent = '';
              this.showToastMessage('No Document found', 'error');
            }
          } else {
            this.doc = '';
            this.htmlContent = '';
            this.documentName = 'No Document found.';
          }
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          }
        },
      });
  }
  ngOnInit() {
    this.route.params.subscribe((parms: any) => {
      if (parms.id) {
        this.loadViewDocumentByButtonId(parms.id);
      }
    });
  }
}
