import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';

import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.loginService.currentUserValue;
    if (currentUser?.idOperator != undefined) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    //this.router.navigate(['login']);
    //return false;
    return this.router.createUrlTree(['/login']);
  }
}
