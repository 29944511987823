import { Component, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RibbonsPageComponent } from '../ribbons/ribbons-page/ribbons-page.component';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-add-levels',
  templateUrl: './add-levels.component.html',
  styleUrl: './add-levels.component.css',
  providers: [MessageService],
})
export class AddLevelsComponent {
  @ViewChild('ribbonPageComponentRef', { static: false })
  ribbonPageComponent: any;
  @ViewChild('ribbonPageGroupComponentRef', { static: false })
  ribbonPageGroupComponent: any;
  @ViewChild('ribbonPageGroupButtonComponentRef', { static: false })
  ribbonPageGroupButtonComponent: any;
  constructor(
    private messageService: MessageService,
    private platformlocation: PlatformLocation
  ) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
  }
  loadDepartment() {
    this.ribbonPageComponent.ngOnInit();
  }
  loadPage() {
    this.ribbonPageGroupComponent.ngOnInit();
  }
  loadButton() {
    this.ribbonPageGroupButtonComponent.ngOnInit();
  }
  showToastMessage($event: any) {
    if ($event.type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: $event.msg,
      });
    if ($event.type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: $event.msg,
      });
  }
}
