<form
  class="main-form py-4"
  [formGroup]="ribbonPageGroupButtonForm"
  (ngSubmit)="addRibbonPageGroupButton()"
>
  <div class="select-box mb-3 form-group">
    <p-dropdown
      [options]="ribbonPageList"
      (onChange)="onRibbonPageChange()"
      [(ngModel)]="selectedRibbonPage"
      optionLabel="ribbonsPageName"
      [showClear]="true"
      placeholder="Select a Department"
      formControlName="ribbonPagename"
      [ngClass]="{ 'is-invalid': submitted && f['ribbonPagename'].errors }"
    ></p-dropdown>
    <div
      *ngIf="submitted && f['ribbonPagename'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="f['ribbonPagename'].errors['required']">
        Please selet Department
      </div>
    </div>
    <label class="select-label" for="input-level2">Department*</label>
  </div>

  <div class="select-box mb-3 form-group">
    <p-dropdown
      [options]="ribbonPageGroupList"
      [(ngModel)]="selectedRibbonPageGroup"
      optionLabel="ribbonsPageGroupName"
      [showClear]="true"
      placeholder="Select a Page"
      formControlName="ribbonPageGroupname"
      [ngClass]="{ 'is-invalid': submitted && f['ribbonPageGroupname'].errors }"
    ></p-dropdown>
    <div
      *ngIf="submitted && f['ribbonPageGroupname'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="f['ribbonPageGroupname'].errors['required']">
        Please selet Page
      </div>
    </div>
    <label class="select-label" for="three-level2">Page*</label>
  </div>

  <div class="form-floating mb-3 form-group">
    <input
      type="text"
      class="form-control"
      id="ribbonsPageGroupButtonName"
      name="ribbonsPageGroupButtonName"
      placeholder="Enter Button"
      [(ngModel)]="addRibbionPageGroupButtonReq.ribbonsPageGroupButtonName"
      formControlName="ribbonPageGroupButtonname"
      [ngClass]="{
        'is-invalid': submitted && f['ribbonPageGroupButtonname'].errors
      }"
    />
    <div
      *ngIf="submitted && f['ribbonPageGroupButtonname'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="f['ribbonPageGroupButtonname'].errors['required']">
        Button is required
      </div>
    </div>
    <label for="input-level3">Button*</label>
  </div>
  <button type="submit" class="btn btn-primary btn-save">Save</button>
</form>
<div class="table-responsive p-0 w-100" style="max-height: 300px">
  <table class="table table-striped m-0">
    <thead class="table-primary position-sticky top-0">
      <tr>
        <th>Button</th>
        <th>Page</th>
        <th>Department</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf = "ribbonPageGroupButtonsGridData && ribbonPageGroupButtonsGridData.length;else other_content">
        <ng-container
        *ngFor="let ribbonPageGroupButton of ribbonPageGroupButtonsGridData"
      >
        <tr>
          <td>{{ ribbonPageGroupButton.ribbonsPageGroupButtonName }}</td>
          <td>{{ ribbonPageGroupButton.ribbonsPageGroupName }}</td>
          <td>{{ ribbonPageGroupButton.ribbonsPageName }}</td>

          <td>
            <button
              (click)="editRibbionPageGroupButton(ribbonPageGroupButton.id)"
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-edit xs mx-1"></i>Edit
            </button>
          </td>
          <ng-container *ngIf="!ribbonPageGroupButton.isDelete;else other_content_td">
            <td *ngIf="!ribbonPageGroupButton.isDelete">
          <button
            (click)="deleteRibbonPageGroupButton(ribbonPageGroupButton.id)"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-trash xs mx-1"></i>Delete
          </button>
        </td>
        </ng-container>
        <ng-template #other_content_td>
          <td >
            
          </td>
        </ng-template>
          
        </tr>
      </ng-container>
      </ng-container>
      <ng-template #other_content>  <tr  id="loading-overlay">
        <td colspan="7" class="loading-icon text-center"> 
            <img src="../../../assets/images/loading.gif" style="width:80px;height:80px;" />
        </td>
        </tr>
      </ng-template>
     
    </tbody>
  </table>
</div>
<!-- <p-table
  [value]="ribbonPageGroupButtonsGridData"
  styleClass="p-datatable-gridlines"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Button</th>
      <th>Page</th>
      <th>Department</th>
      <th>Edit</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-ribbonPageGroupButton>
    <tr>
      <td>{{ ribbonPageGroupButton.ribbonsPageGroupButtonName }}</td>
      <td>{{ ribbonPageGroupButton.ribbonsPageGroupName }}</td>
      <td>{{ ribbonPageGroupButton.ribbonsPageName }}</td>
      <td>
        <p-button
          label="Edit"
          [raised]="true"
          severity="danger"
          (onClick)="editRibbionPageGroupButton(ribbonPageGroupButton.id)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</p-table> -->
