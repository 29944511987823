<ng-template #loginTemplate>
  <app-login></app-login>
</ng-template>
<ng-template #adminTemplate>
  <main class="d-flex align-items-stretch main">
    <div
      class="left-panel flex-shrink-0 bg-white shadow"
      [ngClass]="isMenuStatus ? 'open' : ''"
    >
      <header class="d-flex justify-content-between align-items-center mb-3">
        <figure class="mb-0 logo-hldr">
          <img
            class="img-fluid"
            src="../assets/images/logo-etwin-support.png"
            alt=""
          />
        </figure>

        <span
          class="menu-close d-md-none"
          (click)="isMenuStatus = !isMenuStatus"
          ><img src="../assets/images/icons/icon-close.png" alt=""
        /></span>
      </header>

      <app-left-menu (onMenuOpen)="onMenuClick($event)"></app-left-menu>
    </div>

    <div class="right-panel w-100">
      <div class="d-flex flex-column h-100">
        <header
          class="main-header flex-shrink-0 d-flex justify-content-between justify-content-md-end align-items-center"
        >
          <div class="d-md-none">
            <a href="dashboard.html">
              <figure class="mb-0 logo-hldr">
                <img
                  class="img-fluid"
                  src="../assets/images/logo-etwin-support.png"
                  alt=""
                />
              </figure>
            </a>
          </div>
          <nav class="nav justify-content-end align-items-center">
            <a class="nav-link text-dark" href="#">
              <div class="d-flex align-items-center">
                <p class="mb-0 me-2 me-md-3 fw-bold">{{ username }}</p>
                <img src="../assets/images/profile.png" alt="" />
              </div>
            </a>

            <span
              class="nav-link menu-open d-md-none"
              (click)="isMenuStatus = !isMenuStatus"
              ><img src="../assets/images/icons/icon-hamburger.png" alt=""
            /></span>
          </nav>
        </header>

        <section class="main-section flex-grow-1">
          <router-outlet name="admin"></router-outlet>
        </section>
        <footer class="main-footer">
          <p><small>Copyright. All Rights Reserved</small></p>
        </footer>
      </div>
    </div>
  </main>
</ng-template>
<ng-template #userTemplate>
  <main class="d-flex align-items-stretch main">
    <div
      class="left-panel flex-shrink-0 bg-white shadow"
      [ngClass]="isMenuStatus ? 'open' : ''"
    >
      <header class="d-flex justify-content-between align-items-center mb-3">
        <a href="#">
          <figure class="mb-0 logo-hldr">
            <img
              class="img-fluid"
              src="../assets/images/logo-etwin-support.png"
              alt=""
            />
          </figure>
        </a>
        <span
          class="menu-close d-md-none"
          (click)="isMenuStatus = !isMenuStatus"
          ><img src="../assets/images/profile.png" alt=""
        /></span>
      </header>

      <app-user-left-menu
        (onMenuOpen)="onMenuClick($event)"
      ></app-user-left-menu>
    </div>
    <div class="right-panel w-100">
      <div class="d-flex flex-column h-100">
        <header
          class="main-header flex-shrink-0 d-flex justify-content-between justify-content-md-end align-items-center"
        >
          <div class="d-md-none">
            <a href="#">
              <figure class="mb-0 logo-hldr">
                <img
                  class="img-fluid"
                  src="../assets/images/logo-etwin-support.png"
                  alt=""
                />
              </figure>
            </a>
          </div>
          <nav class="nav justify-content-end align-items-center">
            <a class="nav-link text-dark" href="#">
              <div class="d-flex align-items-center">
                <p class="mb-0 me-3 fw-bold">{{ username }}</p>
                <img src="../assets/images/profile.png" alt="" />
              </div>
            </a>
            <span
              class="nav-link menu-open d-md-none"
              (click)="isMenuStatus = !isMenuStatus"
              ><img src="../assets/images/icons/icon-hamburger.png" alt=""
            /></span>

            <div
              class="logout"
              routerLink="login"
              (click)="confirmPosition('center')"
            >
              <span pTooltip="Logout" tooltipPosition="top"
                ><svg
                  fill="#0096FF"
                  width="32px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                  /></svg
              ></span>
            </div>
          </nav>
        </header>
        <section class="main-section flex-grow-1">
          <h1
            [ngClass]="showOutlet ? 'd-none' : ''"
            style="color: #0096ff; display: flex; justify-content: center"
          >
            WELCOME TO ETWIN DOCUMENT SUPPORT
          </h1>
          <div
            [ngClass]="showOutlet ? 'd-none' : ''"
            class="doc-img w-100 h-100"
          >
            <img src="../assets/images/view-document.jpg" alt="" />
          </div>
          <!-- <router-outlet name="user"></router-outlet> -->
          <router-outlet
            name="user"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          ></router-outlet>
        </section>
        <footer class="main-footer">
          <p><small>Copyright. All Rights Reserved</small></p>
        </footer>
      </div>
    </div>
  </main>
</ng-template>

<!-- admin login -->
<div *ngIf="isLoggedIn; else loginTemplate">
  <div *ngIf="idCompany === 1; then adminTemplate; else userTemplate"></div>
</div>
<!-- <router-outlet></router-outlet> -->
<div class="card flex flex-wrap flex-column align-items-center gap-2">
  <p-toast></p-toast>
  <p-confirmDialog
    key="positionDialog"
    rejectButtonStyleClass="p-button-outlined"
  ></p-confirmDialog>
  <div class="flex flex-wrap justify-content-center gap-2">
    <!-- <p-button (click)="confirmPosition('center')" icon="pi pi-arrow-left" label="Right" styleClass="p-button-help"></p-button> -->
  </div>
</div>
