import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RibbonPage } from '../models/ribbon-page-model';
import { RibbonPageGroup } from '../models/ribbon-page-group-model';
import { RibbonPageGroupButton } from '../models/ribbon-page-group-button-model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class AddLevelsService {
  private ribbionPageData = new BehaviorSubject<any>(null);
  currentRibbionPageData = this.ribbionPageData.asObservable();
  private ribbionPageGroupData = new BehaviorSubject<any>(null);
  currentRibbionPageGroupData = this.ribbionPageGroupData.asObservable();
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient, private loginService: LoginService) {}
  setRibbionPageData(data: RibbonPage[]) {
    this.ribbionPageData.next(data);
  }
  setRibbionPageGroupData(data: RibbonPageGroup[]) {
    this.ribbionPageGroupData.next(data);
  }
  getAllRibbonPages(): Observable<RibbonPage[]> {
    return this.http.get<any>(this.baseApiUrl + 'api/Ribbon/GetAllRibbinPages');
  }
  getAllGridRibbonPages(): Observable<RibbonPage[]> {
    return this.http.get<any>(
      this.baseApiUrl + 'api/Ribbon/GetAllGridRibbinPages'
    );
  }
  getAllRibbonPageGroups(RibbonPageId: any): Observable<RibbonPageGroup[]> {
    return this.http.get<any>(
      this.baseApiUrl +
        'api/Ribbon/GetAllRibbinPageGroups?RibbonPageId=' +
        RibbonPageId
    );
  }
  getAllGridRibbonPageGroups(): Observable<RibbonPageGroup[]> {
    return this.http.get<any>(
      this.baseApiUrl + 'api/Ribbon/GetAllGridRibbinPageGroups'
    );
  }
  getAllGridRibbonPageGroupButtons(): Observable<RibbonPageGroupButton[]> {
    return this.http.get<any>(
      this.baseApiUrl + 'api/Ribbon/GetAllGridRibbinPageGroupButtons'
    );
  }
  getAllRibbonPageGroupButtons(
    RibbonPageGroupId: any
  ): Observable<RibbonPageGroupButton[]> {
    return this.http.get<any>(
      this.baseApiUrl +
        'api/Ribbon/GetAllRibbinPageGroupButtons?RibbonPageGroupId=' +
        RibbonPageGroupId
    );
  }
  addRibbonPage(ribbonPageRequest: RibbonPage): Observable<RibbonPage> {
    if (ribbonPageRequest.id === 0) {
      ribbonPageRequest.createdBy = this.loginService.currentUserValue.username;
      //ribbonPageRequest.createdDate = new Date();
      return this.http.post<RibbonPage>(
        this.baseApiUrl + 'api/Ribbon/AddRibbonPage',
        ribbonPageRequest
      );
    } else {
      ribbonPageRequest.updatedBy = this.loginService.currentUserValue.username;
      //ribbonPageRequest.updatedDate = new Date();
      return this.http.post<RibbonPage>(
        this.baseApiUrl + 'api/Ribbon/UpdateRibbonPage',
        ribbonPageRequest
      );
    }
  }

  addRibbonPageGroup(
    ribbonPageGroupRequest: RibbonPageGroup
  ): Observable<RibbonPageGroup> {
    if (ribbonPageGroupRequest.id === 0) {
      ribbonPageGroupRequest.createdBy =
        this.loginService.currentUserValue.username;
      //ribbonPageGroupRequest.createdDate = new Date();
      return this.http.post<RibbonPageGroup>(
        this.baseApiUrl + 'api/Ribbon/AddRibbonPageGroup',
        ribbonPageGroupRequest
      );
    } else {
      ribbonPageGroupRequest.updatedBy =
        this.loginService.currentUserValue.username;
      //ribbonPageGroupRequest.updatedDate = new Date();
      return this.http.post<RibbonPageGroup>(
        this.baseApiUrl + 'api/Ribbon/UpdateRibbonPageGroup',
        ribbonPageGroupRequest
      );
    }
  }

  addRibbonPageGroupButton(
    ribbonPageGroupButtonRequest: RibbonPageGroupButton
  ): Observable<RibbonPageGroupButton> {
    if (ribbonPageGroupButtonRequest.id === 0) {
      ribbonPageGroupButtonRequest.createdBy =
        this.loginService.currentUserValue.username;
      //ribbonPageGroupButtonRequest.createdDate = new Date();
      return this.http.post<RibbonPageGroupButton>(
        this.baseApiUrl + 'api/Ribbon/AddRibbonPageGroupButton',
        ribbonPageGroupButtonRequest
      );
    } else {
      ribbonPageGroupButtonRequest.updatedBy =
        this.loginService.currentUserValue.username;
      //ribbonPageGroupButtonRequest.updatedDate = new Date();
      return this.http.post<RibbonPageGroupButton>(
        this.baseApiUrl + 'api/Ribbon/UpdateRibbonPageGroupButton',
        ribbonPageGroupButtonRequest
      );
    }
  }
  deleteRibbonPage(ribbonPageId: number): Observable<RibbonPage[]> {
    return this.http.get<RibbonPage[]>(
      this.baseApiUrl +
        'api/Ribbon/DeleteRibbonsPage?ribbonsPageId=' +
        ribbonPageId
    );
  }
  deleteRibbonPageGroup(
    ribbonPageGroupId: number
  ): Observable<RibbonPageGroup[]> {
    return this.http.get<RibbonPageGroup[]>(
      this.baseApiUrl +
        'api/Ribbon/DeleteRibbonsPageGroup?ribbonsPageGroupId=' +
        ribbonPageGroupId
    );
  }
  deleteRibbonPageGroupButton(
    ribbonPageGroupButtonId: number
  ): Observable<RibbonPageGroupButton[]> {
    return this.http.get<RibbonPageGroupButton[]>(
      this.baseApiUrl +
        'api/Ribbon/DeleteRibbonsPageGroupButton?ribbonsPageGroupButtonId=' +
        ribbonPageGroupButtonId
    );
  }
}
