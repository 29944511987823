import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LoginService } from '../../services/login.service';
// import { AuthService } from '../../services/auth.service';

import { LoadingService } from '../../services/loading.service';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  //loading = false;
  submitted = false;
  returnUrl!: string;
  loading!: boolean;
  public resetpassword!: string;
  isTextInput: boolean = false;

  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private platformlocation: PlatformLocation
  ) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  Login() {
    this.loading = true;
    // Perform login operation here
    // Set loading to false when login operation is completed
    this.loading = false;
  }

  ngOnInit() {
    this.loginService.logout();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loadingService.loading$.subscribe(
      (loading) => (this.loading = loading)
    );
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    //this.loading = true;
    // Start loading
    this.loadingService.startLoading();

    this.loginService
      .LoginUser(this.f)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data && data.username != null && data.idCompany == 1) {
            localStorage.setItem('username', data.username);
            var _url = this.router.url.replace('login', '');
            if (_url.includes('dashboard'))
              this.router
                .navigate([{ outlets: { admin: ['dashboard'] } }])
                .then(() => {
                  window.location.href = '/(admin:dashboard)';
                  //window.location.reload();
                });
            else if (_url.includes('add-levels'))
              this.router
                .navigate([{ outlets: { admin: ['add-levels'] } }])
                .then(() => {
                  window.location.href = '/etwinsupport(admin:add-levels)';
                  //window.location.reload();
                });
            else if (_url.includes('upload-documents'))
              this.router
                .navigate([{ outlets: { admin: ['upload-documents'] } }])
                .then(() => {
                  window.location.href = '/(admin:upload-documents)';
                  //window.location.reload();
                });
            else
              this.router
                .navigate([{ outlets: { admin: ['dashboard'] } }])
                .then(() => {
                  window.location.href = '/(admin:dashboard)';
                  //window.location.reload();
                });
          } else if (data && data.username != null && data.idCompany == 2) {
            localStorage.setItem('username', data.username);

            this.router
              .navigate([{ outlets: { user: ['document-view'] } }])
              .then(() => {
                //window.location.reload();
                window.location.href = '/(user:document-view)';
              });
          } else {
            this.error = 'Invalid Credential';
          }
          // Stop loading
          this.loadingService.stopLoading();
          //this.loading = false;
        },
        (error) => {
          this.error = error;
          //this.loading = false;
          // Stop loading in case of an error
          this.loadingService.stopLoading();
        }
      );
  }

  showPass() {
    this.isTextInput = !this.isTextInput;
  }
}
