import { Component } from '@angular/core';
import { LoginService } from './services/login.service';
import { UserInfo } from './models/user-info-model';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { ConfirmationService, MessageService, ConfirmEventType  } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [ConfirmationService, MessageService]

})
export class AppComponent {
  isLoggedIn = false;
  isMenuStatus = false;
  userInfo = UserInfo;
  idCompany: any;
  title = 'EtwinSupportApp';
  username = '';
  public showOutlet: boolean= false;
  
  constructor(private loginService: LoginService,  private router: Router, private confirmationService: ConfirmationService,private messageService: MessageService) {
    

    this.isLoggedIn =
      this.loginService.currentUserValue.idOperator != undefined ? true : false;
    this.idCompany = this.loginService.currentUserValue.idCompany;
    this.username = this.loginService.currentUserValue.username;
    
  }
  
  onMenuClick(event: Event): void {
    this.isMenuStatus = false;
  }
  logout(): void {
    this.loginService.logout();
    localStorage.removeItem('username');
    //this.router.navigate([{ outlets: { default: ['login'] } }]);
    //this.router.navigate(['login']);
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }
 

onActivate(event : any) {
  this.showOutlet = true;
}

onDeactivate(event : any) {
  this.showOutlet = false;
}
position: string = 'center';
   confirmPosition(position: string) {
    this.position = position;
  
    this.confirmationService.confirm({
        message: 'Are you sure you want to Logout?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
           this.logout();
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Request submitted' });
        },
        reject: () => {
            // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Process incomplete', life: 3000 });
        },
        key: 'positionDialog'
         });
      }
 
}
