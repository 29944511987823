import { Component, EventEmitter, Output } from '@angular/core';
import { RibbonPage } from '../models/ribbon-page-model';
import { AddLevelsService } from '../services/add-levels.service';
import { RibbonPageGroup } from '../models/ribbon-page-group-model';
import { RibbonPageGroupButton } from '../models/ribbon-page-group-button-model';
import { EtwinSupportDocuments } from '../models/upload-document-model';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-left-menu',
  templateUrl: './user-left-menu.component.html',
  styleUrl: './user-left-menu.component.css',
  providers: [MessageService],
})
export class UserLeftMenuComponent {
  @Output() onMenuOpen = new EventEmitter<any>();
  ribbonPages: RibbonPage[] = [];
  ribbonPageGroups: RibbonPageGroup[] = [];
  ribbonPageGroupButtons: RibbonPageGroupButton[] = [];
  viewDocuments: EtwinSupportDocuments[] = [];
  ribbonPageId: any;
  ribbonPageGroupId: any;
  searchText: any;
  isRibbionPageMenu: any;
  isRibbionPageGroupMenu: any;
  isRibbionPageGroupButtonMenu: any;
  constructor(
    private addLevelsService: AddLevelsService,
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService
  ) {}
  loadRibbonPages() {
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        this.ribbonPages = ribbonPages;
      },
      error: (response) => {
        if (response.status === 401) {
          this.showToastMessage('Unauthorized', 'error');
        }
      },
    });
  }
  public isMenuOpen(value: any): void {
    this.onMenuOpen.emit(value);
  }
  showToastMessage(msg: string, type: string) {
    if (type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
      });
    if (type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: msg,
      });
  }
  loadRibbonPageGroupsById(pageId: any) {
    this.addLevelsService.getAllRibbonPageGroups(pageId).subscribe({
      next: (ribbonPageGroups) => {
        this.ribbonPageGroups = ribbonPageGroups;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  loadRibbonPageGroupButtonsById(Id: any) {
    this.addLevelsService.getAllRibbonPageGroupButtons(Id).subscribe({
      next: (ribbonPageGroupButtons) => {
        this.ribbonPageGroupButtons = ribbonPageGroupButtons;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }

  ngOnInit() {
    this.loadRibbonpageMenu();
  }
  loadRibbonpageMenu() {
    this.isRibbionPageMenu = true;
    this.isRibbionPageGroupMenu = false;
    this.isRibbionPageGroupButtonMenu = false;
    this.loadRibbonPages();
  }
  ribbonPageMenuClick(id: any) {
    console.log(id);
    this.ribbonPageId = id;
    this.isRibbionPageMenu = false;
    this.isRibbionPageGroupButtonMenu = false;
    this.isRibbionPageGroupMenu = true;
    this.searchText = '';
    this.loadRibbonPageGroupsById(id);
  }
  ribbonPageGroupMenuClick(id: any) {
    console.log(id);
    this.ribbonPageGroupId = id;
    this.isRibbionPageMenu = false;
    this.isRibbionPageGroupMenu = false;
    this.isRibbionPageGroupButtonMenu = true;
    this.searchText = '';
    this.loadRibbonPageGroupButtonsById(id);
  }
  ribbonPageGroupButtonMenuClick(id: any) {
    console.log(id);
    this.isRibbionPageMenu = false;
    this.isRibbionPageGroupMenu = false;
    this.isRibbionPageGroupButtonMenu = true;
    this.searchText = '';
    //this.router.navigate(['/document-viewer', id]);
    this.router.navigate([{ outlets: { user: ['document-viewer', id] } }]);
  }
  logout(): void {
    this.loginService.logout();
    localStorage.removeItem('username');
  }
  backButtonClick() {
    if (this.isRibbionPageGroupButtonMenu == true) {
      this.ribbonPageMenuClick(this.ribbonPageId);
    } else if (this.isRibbionPageGroupMenu == true) {
      this.loadRibbonpageMenu();
    } else {
      this.loadRibbonpageMenu();
    }
  }
}
