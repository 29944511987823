<div class="content-container">
  <p-toast></p-toast>
  <h1>{{ documentName }}</h1>
  <div class="content-area" *ngIf="doc != ''">
    <div
      *ngIf="
        docExtention == '.pdf' ||
          docExtention == '.doc' ||
          docExtention == '.docx';
        then pdfwordTemplate;
        else htmlTemplate
      "
    ></div>
  </div>
</div>
<ng-template #htmlTemplate>
  <div [innerHTML]="htmlContent"></div>
</ng-template>
<ng-template #pdfwordTemplate>
  <div class="office-viewer">
    <iframe
      [src]="urlSafe"
      width="100%"
      height="500px"
      [frameBorder]="0"
    ></iframe>
  </div>
</ng-template>
