import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UploadDocumentsService } from '../../../services/upload-documents.service.service';
import { EtwinSupportDocuments } from '../../../models/upload-document-model';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService, MessageService, ConfirmEventType  } from 'primeng/api';


@Component({
  selector: 'app-upload-documents-grid',
  templateUrl: './upload-documents-grid.component.html',
  styleUrl: './upload-documents-grid.component.css',
  providers: [ConfirmationService, MessageService]
})
export class UploadDocumentsGridComponent implements OnInit, OnChanges {
  supportDocuments: any;
  fileUrl: any;
  @Input() inputSupportDocuments: any;
  position: string = 'center';
  isShowLoader: boolean=false;

  constructor(
    private confirmationService: ConfirmationService,
    private uploadDocumentService: UploadDocumentsService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.isShowLoader= true;
    
    this.uploadDocumentService.getAllEtwinSupportDocuments().subscribe({
      next: (uploadDocuments) => {
        this.isShowLoader=false;
        this.supportDocuments = uploadDocuments;
        this.supportDocuments.forEach(function (item: any) {
          var pos = item.documentName.lastIndexOf('_');
           
            
          if (item.documentName && item.documentName.length >18 ){
            console.log(item)
            item.displyDocumentName =`${item.documentName.substring(0,18)}...${item.documentExt}`;
          }
          else{
            item.displyDocumentName =`${item.documentName}${item.documentExt}`;
          }
        });
      },
      error: (response) => {
        this.isShowLoader= false;
        if (response.status === 401) {
          this.showToastMessage('Unauthorized', 'error');
        }
      },
    });
  }
  ngOnChanges() {
    this.supportDocuments = this.inputSupportDocuments;
  }
  confirmPosition(position: string, id: number, path:string) {
    this.position = position;

    this.confirmationService.confirm({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.deleteDocument(id, path);
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Request submitted' });
        },
        reject: () => {
            // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Process incomplete', life: 3000 });
        },
        key: 'positionDialog'
       });
    }
  async downloadDocument(fileDownloadPath: string) {
    this.fileUrl = fileDownloadPath;
    this.uploadDocumentService
      .documentsDownload(this.fileUrl)
      .subscribe(async (event) => {
        let data = event as HttpResponse<Blob>;
        const downloadedFile = new Blob([data.body as BlobPart], {
          type: data.body?.type,
        });
        console.log('ddd', downloadedFile);
        if (downloadedFile.type != '') {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          var pos = this.fileUrl.lastIndexOf('_');
          this.fileUrl = this.fileUrl.substring(0, pos);
          a.download = this.fileUrl;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }
      });
  }
  showToastMessage(msg: string, type: string) {
    if (type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
      });
    if (type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: msg,
      });
  }
  async deleteDocument(documentid: number, path: string) {
    this.uploadDocumentService
      .deleteEtwinSupportDocuments(documentid, path)
      .subscribe({
        next: (uploadDocuments) => {
          this.supportDocuments = uploadDocuments;
          this.isShowLoader=false;
          this.supportDocuments.forEach(function (item: any) {
            console.log(item);
            var pos = item.documentName.lastIndexOf('_');
            item.displyDocumentName =
              item.documentName.substring(0, pos) + item.documentExt;
          });
          this.showToastMessage('Documents deleted Successfully', 'success');
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorised', 'error');
          } else {
            this.showToastMessage('Error while deleting Document', 'error');
          }
          console.log(response);
        },
      });
  }
}
