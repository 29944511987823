import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddLevelsComponent } from './components/add-levels/add-levels.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { LoginComponent } from './components/login/login.component';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { AuthGuard } from './guards/auth.guard';
import { DocumentViewerComponent } from './components/document-view/document-viewer/document-viewer.component';
import { AppComponent } from './app.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    //outlet: 'admin',
    //canActivate: [AuthGuard],
  },

  {
    path: 'login',
    component: LoginComponent,
    //pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    outlet: 'admin',
    canActivate: [AuthGuard],
  },
  {
    path: 'add-levels',
    component: AddLevelsComponent,
    outlet: 'admin',
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-documents',
    component: UploadDocumentsComponent,
    outlet: 'admin',
    canActivate: [AuthGuard],
  },

  {
    path: 'document-view',
    component: DocumentViewComponent,
    outlet: 'user',
    canActivate: [AuthGuard],
  },
  {
    path: 'document-viewer/:id',
    component: DocumentViewerComponent,
    canActivate: [AuthGuard],
    outlet: 'user',
  },

  { path: '**', redirectTo: '/login' }, // Redirect unmatched routes to login
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
