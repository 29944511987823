import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    return items.filter((item) => {
      return Object.keys(item).some((key: any) => {
        if (
          key == 'ribbonsPageGroupName' ||
          key == 'ribbonsPageName' ||
          key == 'ribbonsPageGroupButtonName'
        ) {
          return String(item[key])
            .toLowerCase()
            .includes(searchText.toLowerCase());
        } else {
          return;
        }
      });
    });
  }
}
