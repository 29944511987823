<ul class="nav flex-column position-relative main-nav admin">
  <li class="nav-item">
    <a
      [routerLinkActive]="['active']"
      [routerLink]="[{ outlets: { admin: ['add-levels'] } }]"
      [routerLinkActive]="['active']"
      (click)="isMenuOpen(false)"
      class="nav-link d-flex align-items-center"
    >
      <img
        class="img-fluid flex-shrink-0"
        src="../assets/images/icons/icon-plus.png"
        alt=""
      />

      <span class="ms-2">Add Levels</span></a
    >
  </li>
  <li class="nav-item">
    <a
      [routerLink]="[{ outlets: { admin: ['upload-documents'] } }]"
      [routerLinkActive]="['active']"
      (click)="isMenuOpen(false)"
      class="nav-link d-flex align-items-center"
    >
      <img
        class="img-fluid flex-shrink-0"
        src="../assets/images/icons/icon-upload.png"
        alt=""
      />

      <span class="ms-2">Upload Documents</span></a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="login"
      (click)="confirmPosition('center')"
      class="nav-link d-flex align-items-center"
    >
      <img
        class="img-fluid flex-shrink-0"
        src="../assets/images/icons/icon-logout.png"
        alt=""
      />

      <span class="ms-2">Logout</span></a
    >
  </li>
</ul>
<div class="flex-wrap flex-column align-items-center gap-2">
  <p-toast></p-toast>
  <p-confirmDialog key="positionDialog" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
  <div class="flex flex-wrap justify-content-center gap-2">
     
      <!-- <p-button (click)="confirmPosition('center')" icon="pi pi-arrow-left" label="Right" styleClass="p-button-help"></p-button> -->
  </div>
  
  </div>
