<div class="container-fluid p-0" style="height: 100%">
  <main
    role="main"
   
    style="
      width: 100%;
      height: 100vh;
      background-image: url('../../../../assets/images/BackgroundLogin.png');
      background-size: cover;
    "
  >
    <div class="login_div">
      <div>
        <!-- <h4 class="card-header">Basic Auth Login Example</h4> -->
        <div class="logo_div">
          <a href="/Home/Index"
            ><img
              src="../../../assets/images/logo-etwin-support.png"
              alt=""
              class=""
          /></a>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <i class="fas fa-user-circle" id="left_icon"></i>
              <div class="w-100">
                <input
                  type="text" 
                  formControlName="username"
                  id="uname" 
                  [ngClass]="error ? 'login-input-error' : ''"
                  class="form-control" 
                  id="uname" 
                  placeholder="Username" 
                  [ngClass]="{
                    'is-invalid': submitted && f['username'].errors
                  }"
                />
                <div
                  *ngIf="submitted && f['username'].errors"
                  class="invalid-feedback"
                  id="username_notif"
                >
                  <div *ngIf="f['username'].errors['required']">
                    Attenzione, inserire username
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group pt-4">
              <i class="fas fa-lock left_icon" id="" (click)="!this.isTextInput"></i>
              <div>
                <input
                 [type]= "isTextInput ? 'text' : 'password'"
                  formControlName="password"
                  id="pwd"
                  [ngClass]="error ? 'login-input-error' : ''"
                  class="form-control" 
                  placeholder="Password"
                  [ngClass]="{
                    'is-invalid': submitted && f['password'].errors
                  }"
                />
                <div
                  *ngIf="submitted && f['password'].errors"
                  class="invalid-feedback"
                  id="paswword_notif"
                >
                  <div *ngIf="f['password'].errors['required']">
                    Attenzione, inserire password
                  </div>
                </div>
              </div>
              <!-- <div id="error_notif">
                <i class="fas fa-times" style="color:red;"></i>
                <span style="color:red; font-weight: bold;">Credenziali non valide</span>
            </div> -->

              <i class="fas fa-eye toggle_pwd" id="toggle_pwd" (click)="showPass()"></i>
            </div>
            <!-- <div class="form-group pt-4">
              <div class="row">
                <div class="remember_container p-0 ">
                  <input
                    type="checkbox"
                    class="form-check-inline"
                    id="remember_me"
                  />
                  <label class="rme_class">Remember me</label>
                </div>
                <div class="forgotpw_container p-0">
                  <a href="/Operators/PasswordRecovery" class="forgot_pwd">
                    Forgot password?
                  </a>
                </div>
              </div>
            </div> -->
            <!-- <button [disabled]="loading" class="login_btn">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Login
            </button> -->
            <button [disabled]="loading" class="login_btn">
              <ng-container *ngIf="loading; else loginContent">
                  ...Loading
              </ng-container>
              <ng-template #loginContent>
                  Login
              </ng-template>
          </button>

          

            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div>
          </form>

          <div class="follow_blocker pt-2 pb-2">
            <ul class="social_icons">
              <li>
                <a href="https://www.virevo.it/" target="_blank">
                  <img src="../../../../assets/images/virevo.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/virevo/"
                  target="_blank"
                >
                  <img src="../../../../assets/images/linkedin.png" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/virevo_digital_kaizen/"
                  target="_blank"
                >
                  <img src="../../../../assets/images/instagram.png" alt="" />
                </a>
              </li>
              <li>
                <a href="https://rb.gy/tmmod" target="_blank">
                  <img src="../../../../assets/images/youtube.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" id="loading-overlay">
      <div class="loading-icon"> 
          <img src="../../../assets/images/loading.gif" style="width:80px;height:80px;" />
      </div>
    </div>
  </main>
 
</div>