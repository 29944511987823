import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddLevelsComponent } from './components/add-levels/add-levels.component';
import { RibbonsPageComponent } from './components/ribbons/ribbons-page/ribbons-page.component';
import { RibbonsPageGroupComponent } from './components/ribbons/ribbons-page-group/ribbons-page-group.component';
import { RibbonsPageGroupButtonComponent } from './components/ribbons/ribbons-page-group-button/ribbons-page-group-button.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { UploadDocumentsGridComponent } from './components/upload-documents/upload-documents-grid/upload-documents-grid.component';
import { LoginComponent } from './components/login/login.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterStringPipe } from './pipes/filter.string.pipe';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { DocumentViewerComponent } from './components/document-view/document-viewer/document-viewer.component';
import { UserLeftMenuComponent } from './user-left-menu/user-left-menu.component';
import { JwtInterceptor } from './guards/jwt.interceptor.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  declarations: [
    AppComponent,
    AddLevelsComponent,
    RibbonsPageComponent,
    RibbonsPageGroupComponent,
    RibbonsPageGroupButtonComponent,
    DashboardComponent,
    AddLevelsComponent,
    UploadDocumentsComponent,
    UploadDocumentsGridComponent,
    LoginComponent,
    LeftMenuComponent,
    DocumentViewComponent,
    DocumentViewerComponent,
    UserLeftMenuComponent,
    FilterPipe,
    FilterStringPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DropdownModule,
    ChartModule,
    TableModule,
    ButtonModule,
    BrowserAnimationsModule,
    ToastModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TooltipModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
