import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserInfo } from '../models/user-info-model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<UserInfo>;
  private userInfo = new BehaviorSubject<any>(null);
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) {
    var user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.currentUserSubject = new BehaviorSubject<UserInfo>(user || '');
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserInfo {
    return this.currentUserSubject.value;
  }
  get getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }
  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.userInfo.next(null);
  }
  LoginUser(LoginInfo: any): Observable<any> {
    const url = this.baseApiUrl + 'api/Login/ValidateLoginUser';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const params = {
      Username: LoginInfo.username.value,
      Password: LoginInfo.password.value,
      RememberUser: '',
    };
    var userInfo = this.http.get<any>(
      url +
        '?Username=' +
        LoginInfo.username.value +
        '&Password=' +
        LoginInfo.password.value +
        '&RememberUser=' +
        LoginInfo.username.value
    );
    userInfo.subscribe((user) => {
      if (user && user != null && user.idCompany != 0 && user.idOperator != 0) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        //localStorage.setItem('currentUser', JSON.stringify(user).);
        this.currentUserSubject.next(user);
        this.userInfo.next(user);
      } else {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.userInfo.next(null);
      }
    });

    return userInfo;
  }
}
