<!-- <div class="page-title mb-4">
  <h1>Dashboard</h1>
</div>
<div class="d-flex align-items-stretch">
  <div class="flex-grow-1 pe-5">
    <figure class="mb-0">
        <img class="w-100" src="../assets/images/dashboard-placeholder.jpg" alt="">
      </figure>
  </div>
</div> -->

<section>
  <div class="page-title mb-4">
    <h1>Dashboard</h1>
  </div>
  <div class="d-flex align-items-stretch">
    <div class="flex-grow-1 pe-5">
      <figure class="mb-0">
        <img
          class="w-100"
          src="../assets/images/dashboard-placeholder.jpg"
          alt=""
        />
      </figure>
    </div>
    <aside class="main-aside flex-shrink-0">
      <h4>Recent Uploads</h4>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" href="#">Documento_Uno.pdf</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Documento_Due.doc</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Documento_Tre.pdf</a>
        </li>
      </ul>
    </aside>
  </div>
</section>
