import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { EtwinSupportDocuments } from '../../models/upload-document-model';
import { AddLevelsService } from '../../services/add-levels.service';
import { RibbonPageGroup } from '../../models/ribbon-page-group-model';
import { RibbonPage } from '../../models/ribbon-page-model';
import { RibbonPageGroupButton } from '../../models/ribbon-page-group-button-model';
import { UploadDocumentsService } from '../../services/upload-documents.service.service';
import { MessageService } from 'primeng/api';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrl: './upload-documents.component.css',
  providers: [MessageService],
})
export class UploadDocumentsComponent implements OnInit {
  @ViewChild('file')
  myInputFileVariable: any;
  fileDocument: any;
  otherParams: any;
  documentType: any;
  currentDate: any = new Date();
  ribbonPageList: RibbonPage[] | undefined;
  selectedRibbonPage: RibbonPage | undefined;
  ribbonPageGroupList: RibbonPageGroup[] | undefined;
  selectedRibbonPageGroup: RibbonPageGroup | undefined;
  ribbonPageGroupButtonList: RibbonPageGroupButton[] | undefined;
  selectedRibbonPageGroupBtton: RibbonPageGroupButton | undefined;
  parentsupportDocuments: any;
  uploadDocumentsForm: FormGroup = new FormGroup({
    ribbonPagename: new FormControl(''),
    ribbonPageGroupname: new FormControl(''),
    ribbonPageGroupButtonname: new FormControl(''),
    uploadDocumentName: new FormControl(''),
  });
  submitted = false;
  createdBy = '';
  constructor(
    private addLevelsService: AddLevelsService,
    private uploadDocumentService: UploadDocumentsService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private platformlocation: PlatformLocation
  ) {
    history.pushState(null, '', location.href);
    this.platformlocation.onPopState(() => {
      history.pushState(null, '', location.href);
    });
  }
  showToastMessage(msg: string, type: string) {
    if (type == 'success')
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
      });
    if (type == 'error')
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: msg,
      });
  }
  ngOnInit(): void {
    this.uploadDocumentsForm = this.formBuilder.group({
      ribbonPagename: ['', Validators.required],
      ribbonPageGroupname: ['', Validators.required],
      ribbonPageGroupButtonname: ['', Validators.required],
      uploadDocumentName: ['', Validators.required],
    });
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        //console.log(ribbonPages);
        this.ribbonPageList = ribbonPages;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.uploadDocumentsForm.controls;
  }
  uploadFile = (files: any) => {
    if (files.length === 0) {
      return;
    }
    this.fileDocument = <File>files[0];
  };
  onrChange(e: any) {
    this.documentType = e.target.value;
  }
  addEtwinSupportDocuments() {
    this.submitted = true;
    if (this.uploadDocumentsForm.invalid) {
      return;
    }
    this.otherParams = {
      idRibbonsPage: this.selectedRibbonPage?.id,
      idRibbonsPageGroup: this.selectedRibbonPageGroup?.id,
      idRibbonsPageGroupButton: this.selectedRibbonPageGroupBtton?.id,
      documentType: this.documentType,
      createdBy: this.createdBy,
      updatedBy: this.createdBy,
    };

    this.uploadDocumentService
      .addEtwinSupportDocuments(this.fileDocument, this.otherParams)
      .subscribe({
        next: (addEtwinSupportDocumentsReq) => {
          console.log(addEtwinSupportDocumentsReq);
          this.showToastMessage('Documents Saved Successfully', 'success');
          this.uploadDocumentService.getAllEtwinSupportDocuments().subscribe({
            next: (uploadDocuments) => {
              this.parentsupportDocuments = uploadDocuments;
              this.parentsupportDocuments.forEach(function (item: any) {
                console.log(item);
                var pos = item.documentName.lastIndexOf('_');
                item.displyDocumentName =
                  item.documentName.substring(0, pos) + item.documentExt;
              });
              this.clearDocumentFields();
            },
            error: (response) => {
              if (response.status === 401) {
                this.showToastMessage('Unauthorized', 'error');
              }
            },
          });
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          } else {
            this.showToastMessage('Error while Saving Documents', 'error');
          }
        },
      });
  }
  onRibbonPageChange() {
    this.addLevelsService
      .getAllRibbonPageGroups(this.selectedRibbonPage?.id)
      .subscribe({
        next: (ribbonPageGroups) => {
          this.ribbonPageGroupList = ribbonPageGroups;
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          }
        },
      });
  }
  onRibbonPageGroupChange() {
    this.addLevelsService
      .getAllRibbonPageGroupButtons(this.selectedRibbonPageGroup?.id)
      .subscribe({
        next: (ribbonPageGroupButtons) => {
          this.ribbonPageGroupButtonList = ribbonPageGroupButtons;
        },
        error: (response) => {
          if (response.status === 401) {
            this.showToastMessage('Unauthorized', 'error');
          }
        },
      });
  }
  clearDocumentFields() {
    this.selectedRibbonPage = undefined;
    this.selectedRibbonPageGroup = undefined;
    this.selectedRibbonPageGroupBtton = undefined;
    this.documentType = 'Guide';
    this.myInputFileVariable.nativeElement.value = '';
  }
}
