import { Component, EventEmitter, Output } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, ConfirmEventType  } from 'primeng/api';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.css',
  providers: [ConfirmationService, MessageService]
})
export class LeftMenuComponent {
  @Output() onMenuOpen = new EventEmitter<any>();
  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute, 
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}
  public isMenuOpen(value: any): void {
    this.onMenuOpen.emit(value);
  }
  logout(): void {
    this.loginService.logout();
    localStorage.removeItem('username');
    //this.router.navigate([{ outlets: { default: ['login'] } }]);
    //this.router.navigate(['login']);
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }
  position: string = 'center';
   confirmPosition(position: string) {
    this.position = position;
  
    this.confirmationService.confirm({
        message: 'Are you sure you want to Logout?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
           this.logout();
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Request submitted' });
        },
        reject: () => {
            // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Process incomplete', life: 3000 });
        },
        key: 'positionDialog'
         });
      }
}
