import { Component, EventEmitter, Output } from '@angular/core';
import { AddLevelsService } from '../../../services/add-levels.service';
import { RibbonPage } from '../../../models/ribbon-page-model';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-ribbons-page',
  templateUrl: './ribbons-page.component.html',
  styleUrl: './ribbons-page.component.css',
})
export class RibbonsPageComponent {
  loading!: boolean;
  @Output() onShowToastMessage = new EventEmitter<any>();
  ribbonPages: RibbonPage[] = [];
  ribbonPagesData: RibbonPage[] = [];
  ribbonPagesGridData: RibbonPage[] = [];
  //ribbonPageForm!: FormGroup;
  ribbonPageForm: FormGroup = new FormGroup({
    ribbonPagename: new FormControl(''),
  });
  submitted = false;
  createdBy = '';
  addRibbionPageReq: RibbonPage = {
    id: 0,
    ribbonsPageName: '',
    createdBy: this.createdBy,
    createdDate: new Date(),
    updatedBy: this.createdBy,
    updatedDate: new Date(),
    isDelete: false,
  };
  constructor(
    private addLevelsService: AddLevelsService,
    private formBuilder: FormBuilder
  ) {}
  loadRibbonPages() {
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        this.ribbonPagesGridData = ribbonPages;
        this.ribbonPages = ribbonPages;
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        }
        console.log(response);
      },
    });
  }
  loadGridRibbonPages() {
    this.addLevelsService.getAllGridRibbonPages().subscribe({
      next: (ribbonPages) => {
        this.ribbonPagesGridData = ribbonPages;
        this.ribbonPages = ribbonPages;
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        }
        console.log(response);
      },
    });
  }
  ngOnInit() {
    this.loadGridRibbonPages();
    this.ribbonPageForm = this.formBuilder.group({
      ribbonPagename: ['', Validators.required],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.ribbonPageForm.controls;
  }
  addRibbonPage() {
    this.submitted = true;

    if (this.ribbonPageForm.invalid) {
      return;
    }
    this.addLevelsService.addRibbonPage(this.addRibbionPageReq).subscribe({
      next: (response) => {
        this.addLevelsService.getAllGridRibbonPages().subscribe({
          next: (ribbonPages) => {
            this.loading = false;
            this.addLevelsService.setRibbionPageData(ribbonPages);
            this.ribbonPagesGridData = ribbonPages;
            this.ribbonPages = ribbonPages;
            this.clearRibbonPageFields();
            this.onShowToastMessage.emit({
              msg: 'Department Saved Successfully',
              type: 'success',
            });
          },
          error: (response) => {
            this.loading = false;
            if (response.status === 401) {
              this.onShowToastMessage.emit({
                msg: 'Unauthorized',
                type: 'error',
              });
            } else {
              this.onShowToastMessage.emit({
                msg: 'Error while Saving Department',
                type: 'error',
              });
            }
          },
        });
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        }
        console.log(response);
      },
    });
  }
  clearRibbonPageFields() {
    this.addRibbionPageReq.id = 0;
    (this.addRibbionPageReq.ribbonsPageName = ''),
      (this.addRibbionPageReq.createdBy = ''),
      (this.addRibbionPageReq.createdDate = new Date()),
      (this.addRibbionPageReq.updatedBy = ''),
      (this.addRibbionPageReq.updatedDate = new Date());
  }
  getRibbonPageById(id: number) {
    this.ribbonPagesData = JSON.parse(JSON.stringify(this.ribbonPages));
    var obj = this.ribbonPagesData.filter(function (node) {
      return node.id == id;
    });

    return obj[0];
  }
  async editRibbonPage(ribbonPageId: number) {
    console.log(ribbonPageId);
    console.log(this.ribbonPages);
    this.addRibbionPageReq = this.getRibbonPageById(ribbonPageId);
  }
  async deleteRibbonPage(ribbonPageId: number) {
    this.addLevelsService.deleteRibbonPage(ribbonPageId).subscribe({
      next: (ribbonPages) => {
        this.ribbonPages = ribbonPages;
        this.ribbonPagesGridData = ribbonPages;
        this.addLevelsService.setRibbionPageData(ribbonPages);
        this.onShowToastMessage.emit({
          msg: 'Department deleted Successfully',
          type: 'success',
        });
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        } else {
          this.onShowToastMessage.emit({
            msg: 'Error while deleting Department',
            type: 'error',
          });
        }
      },
    });
  }
}
