<div class="page-title mb-4">
  <p-toast></p-toast>
  <h1>Add Menu Levels</h1>
</div>
<div class="d-flex flex-column flex-lg-row align-items-stretch">
  <div class="flex-grow-1 ">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="level1-tab"
          data-bs-toggle="tab"
          data-bs-target="#level1-tab-pane"
          type="button"
          role="tab"
          aria-controls="level1-tab-pane"
          aria-selected="true"
          (click)="loadDepartment()"
        >
          Department
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="level2-tab"
          data-bs-toggle="tab"
          data-bs-target="#level2-tab-pane"
          type="button"
          role="tab"
          aria-controls="level2-tab-pane"
          aria-selected="false"
          (click)="loadPage()"
        >
          Page
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="level3-tab"
          data-bs-toggle="tab"
          data-bs-target="#level3-tab-pane"
          type="button"
          role="tab"
          aria-controls="level3-tab-pane"
          aria-selected="false"
          (click)="loadButton()"
        >
          Button
        </button>
      </li>
    </ul>
    <div class="tab-content" id="levels-tab">
      <div
        class="tab-pane fade show active"
        id="level1-tab-pane"
        role="tabpanel"
        aria-labelledby="level1-tab"
        tabindex="0"
      >
        <app-ribbons-page
          #ribbonPageComponentRef
          (onShowToastMessage)="showToastMessage($event)"
        ></app-ribbons-page>
      </div>
      <div
        class="tab-pane fade"
        id="level2-tab-pane"
        role="tabpanel"
        aria-labelledby="level2-tab"
        tabindex="0"
      >
        <app-ribbons-page-group
          #ribbonPageGroupComponentRef
          (onShowToastMessage)="showToastMessage($event)"
        ></app-ribbons-page-group>
      </div>
      <div
        class="tab-pane fade"
        id="level3-tab-pane"
        role="tabpanel"
        aria-labelledby="level3-tab"
        tabindex="0"
      >
        <app-ribbons-page-group-button
          #ribbonPageGroupButtonComponentRef
          (onShowToastMessage)="showToastMessage($event)"
        ></app-ribbons-page-group-button>
      </div>
    </div>
  </div>
</div>
