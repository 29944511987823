export class UserInfo {
  idOperator: number = 0;
  nameSurname: string = '';
  idCompany: number = 0;
  username: string = '';
  password: string = '';
  rememberPassword: boolean = false;
  active: boolean = false;
  email: string = '';
  token: string = '';
}
