import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  startLoading(): void {
    this.loadingSubject.next(true);
  }

 constructor() { }

 stopLoading(): void {
  this.loadingSubject.next(false);
}
}

